import React from 'react';
import styled from 'styled-components';
import StatesmanBackground from '../../images/Group.png';
import ReadMoreButton from '../../components/common/ReadMoreButton';
import MobileContainer from './mobile/satesman';
import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${StatesmanBackground});
  display: grid;
  grid-template-columns: 57.6vw 42.4vw;
  grid-template-rows: 100%;
  background-size: cover;
`;

const RightContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-family: Chapaza;
    font-size: 60px;
    color: #F3F3F3;
    margin-bottom: 9.7vh;

    @media (max-width: 1360px) {
      font-size: 48px;
    }
  }

  .text {
    font-family: 'Lato';
    font-size: 22px;
    color: #FFFFFF;
    max-width: 26.6vw;
    margin-bottom: 8vh;

    @media (max-width: 1360px) {
      font-size: 20px;
    }
  }
`;

const Statesman = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer />
    ) : (
        <Container>
          <RightContainer>
            <div className="title">Statesman</div >
            <div className="text">
              Bill Armstrong was a leader on the national stage and a transformative figure in Colorado politics for 50 years. A State Legislator, Congressman, and U.S. Senator, he also chaired the influential Senate Republican Policy Committee. He became a conservative favorite for President, but instead decided to return home to Colorado, modeling the “citizen legislator” standard he believed in.
        </div>
            <ReadMoreButton route="/bio/statesman" color="white" styles={{ margin: 'unset' }} />
          </RightContainer >
        </Container >
      )
  );
}

export default Statesman;