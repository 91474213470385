import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
  width: 220px;
  height: 55px;
  margin: auto;
  padding-top: 15px;
`;

const Button = styled.div`
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
  font-family: 'Lato';
  font-size: 18px;
  text-align: center;
  opacity: 0.6;
  padding: 10px;
`;

const ReadMoreButton = ({ color, styles = {}, route }) => {
  return (
    <div style={{ width: '15.3vw', height: '5.5vh', margin: 'auto', paddingTop: '15px', ...styles }}>
      <Link to={route} style={{ textDecoration: 'none' }}>
        <Button color={color}>Read More</Button>
      </Link>
    </div>
  )
}

export default ReadMoreButton;