import React from 'react';
import styled from 'styled-components';

import EducatorImg from '../../images/Group 6.png'
import EducatorCopy from '../../images/educatorCopy.png';

import ReadMoreButton from '../common/ReadMoreButton';
import MobileContainer from './mobile/educator';

import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 110vh;
  width: 100%;
  display: grid;
  grid-template-columns: 31.6vw 34.6vw 22.8vw;
  grid-template-rows: 100%;
  column-gap: 5.5vw;

  .left-image {
    max-width: 35vw;
    max-height: 83vh;
    margin-top: 7vh;
    margin-left: 72px;
    grid-column: 1;
    grid-row: 1;
  }
`;

const MiddleContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin-left: 87px;
`;

const TextContainer = styled.div`
  font-family: 'Lato';
  font-size: 22px;
  line-height: 30px;
  margin-top: 10vh;

  @media (max-width: 1360px) {
    font-size: 18px;
  }
`;

const EducatorText = styled.div`
  font: 'Chapaza';
  font-size: 60px;
  color: #212028;
  grid-column: 2;
  grid-row: 1;
  margin-left: 32.5vw;
  margin-bottom: 20vh;
  align-self: end;

  @media (max-width: 1360px) {
    font-size: 48px;
  }
`;

const Educator = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer />
    ) : (
        <Container>
          <img className="left-image" src={EducatorImg} />
          <MiddleContainer>
            <TextContainer>
              Bill Armstrong’s final career was as President of Colorado Christian University, which he called the most important work of his life. During his 10-year tenure, enrollment more than doubled, and CCU achieved higher academic standards, a stronger curriculum, and a campus dotted with new buildings. Today’s “Armstrong Legacy Initiative” is the cornerstone of CCU’s future plans, and the Centennial Institute’s Western Conservative Summit is the largest gathering of conservatives outside Washington, D.C.
        </TextContainer>
            <ReadMoreButton route="/bio/educator" color="#C84836" styles={{ gridColumn: 2, gridRow: 1, justifySelf: 'center', margin: 'unset', marginTop: '6vh' }} />
          </MiddleContainer>
          <EducatorText>Educator</EducatorText>
          <img src={EducatorCopy} style={{ gridColumn: 3, gridRow: 1, maxWidth: '21.9vw', maxHeight: '52.5vh', justifySelf: 'end', marginTop: '18.7vh' }} />
        </Container >
      )

  )
}

export default Educator;