import React from "react"
import styled from "styled-components"
import ArmstrongCutout from "../../../assets/ArmstrongCutout.svg"

import { FirstName, LastName, Line } from "../title"
import Button from "../../../components/common/StandardButton"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 85vh;
  padding-top: 20%;
  margin-left: 20%;
  margin-right: 20%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  svg {
    max-width: 100vw;
  }
`

const Description = styled.div`
  margin-top: 16px;
  .title {
    font-family: Chapanza;
    color: #c84836;
    font-size: 16px;
  }

  .text {
    font-family: "Lato";
    font-size: 18px;
  }
`

const MobileAboutHome = ({ handleModal }) => (
  <Container>
    <div>
      <FirstName>Bill</FirstName>
      <LastName>Armstrong</LastName>
    </div>
    <ArmstrongCutout />
    <div>
      <Line style={{ marginTop: 0 }} />
      <Description>
        <div className="title">The Armstrong Project</div>
        <div className="text">
          The Armstrong Project preserves and promotes the legacy of Senator
          William Armstrong. Watch the 2024 William L. Armstrong Award film now!
        </div>
      </Description>
    </div>
    <Button
      cursor={"pointer"}
      onClick={handleModal}
      text={"Watch Now"}
      color="#C84836"
      styles={{ margin: "none", marginTop: "40px", width: "200px" }}
    />
  </Container>
)

export default MobileAboutHome
