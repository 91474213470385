import React from 'react';
import styled from 'styled-components';

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #C84836;

   .title {
    font-family: Chapaza;
    font-size: 10.5vw;
    color: #F3F3F3;
  }

  .text {
    font-family: 'Lato';
    font-size: 4.3vw;
    color: #FFFFFF;
    max-width: 80%;

  }
`;


const Statesman = () => (
  <MobileContainer>
    <div className="title">Statesman</div >
    <div className="text">
      Bill Armstrong was a leader on the national stage and a transformative figure in Colorado politics for 50 years. A State Legislator, Congressman, and U.S. Senator, he also chaired the influential Senate Republican Policy Committee. He became a conservative favorite for President, but instead decided to return home to Colorado, modeling the “citizen legislator” standard he believed in.
    </div>
  </MobileContainer>
);

export default Statesman;