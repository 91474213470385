import React from 'react';
import styled from 'styled-components';
import background from '../../../images/Bitmap.png';


const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #aa4d3b;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    max-width: 80%;
    max-height: 80%;
    margin: 10%;
  }
  
  .text {
    background-color: white;
    font-family: 'Lato';
    font-size: 2.3vw;
    line-height: 30px;
    width: 80%;
    height: 80%;
    box-shadow: 0px -4px 50px rgba(50, 50, 50, 0.3);
    padding: 20px;

      h1 {
        font-family: 'Chapaza';
        color: #212028;
        font-size: 10.0vw;
        line-height: 70px;
        font-weight: normal;
        margin: auto;
        text-align: center;
      }
      
      .line {
        width: 94px;
        height: 2px;
        background-color: #C84836;
        margin: 25px auto;
      }
  }
`;

const Broadcaster = () => (
  <MobileContainer>
    <div className="text">
      <h1>Broadcaster</h1>
      <div className="line" />
      <div>
        At 19, Bill Armstrong was America’s first teenage Top 40 Disc Jockey, beginning his lifetime in the broadcasting business. In 1959 he bought KOSI radio and became a well-known personality in Denver. He was president of Armstrong Broadcasting Company and Ambassador Media Corporation, owned a major daily newspaper and three TV stations, and served on the board of the AP Broadcasters Association.
      </div>
      <img src={background} />
    </div>
  </MobileContainer>
);

export default Broadcaster;