import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../hooks/useViewport';
import AboutBillImg from '../../images/Group 11.png';
import ReadMoreButton from '../common/ReadMoreButton';
import MobileContainer from './mobile/about';


const Container = styled.div`
  display: grid;
  grid-template-columns: 26.7vw 73.3vw;
  grid-template-rows: 100%;
  margin-bottom: 12vh;
  height: 100vh;

  .title {
    font-family: 'Chapaza';
    font-size: 60px;
    grid-column: 1;
    align-self: center;
    margin-left: 72px;

    @media (max-width: 1360px) {
      font-size: 46px;
    }
  }

  .shaded {
    grid-column: 2;
    grid-row: 1;
    background-color: #F6F4EF;
    display: grid;
    grid-template-columns: 400px 409px;
    grid-template-rows: 100%;
    grid-column-gap: 70px;
    padding: 151px 110px 124px 117px;
    
    @media (max-width: 1360px) {
      grid-template-columns: 300px 309px;
    }

    img {
      grid-column: 1;
      grid-row: 1;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text {
    padding-top: 40px;
    font-family: 'Lato';
    font-size: 22px;
    color: #212028;
    width: 28.4vw;
    grid-column 2;
    grid-row: 1;

    @media (max-width: 1360px) {
      font-size: 18px;
      width: 25vw;
    }
  }
`;

const AboutBillArmstrong = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer />
    ) : (
        <Container>
          <div className="title">Senator Bill < br /> Armstrong</div >
          <div className="shaded">
            <img src={AboutBillImg} />
            <div className="text">
              William L. Armstrong was born in Fremont, Nebraska March 16, 1937, and died July 15, 2016 in Denver. He was a highly successful businessman, starting in radio, then newspapers and television, and eventually the financial services and real estate businesses. At the same time, he was a pivotal figure in Colorado politics for 50 years. He became an important national leader in the U.S. Senate, an influential figure in the Christian evangelical movement, and led one of the nation’s top private Christian universities.

          <ReadMoreButton route="/bio/about" color="#C84836" styles={{ margin: 'unset', marginTop: '58px' }} />
            </div>
          </div>
        </Container >
      )
  );
}

export default AboutBillArmstrong;