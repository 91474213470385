import React from 'react';
import styled from 'styled-components';

import ChristianActivistImg from '../../images/president-armstrong-ccu-inauguration.png';
import ReadMoreButton from '../../components/common/ReadMoreButton';
import MobileContainer from './mobile/christian';
import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  display: grid;
  grid-template-columns: 41.2vw 58.8vw;
  grid-template-rows: 100%;
  height: 125vh;

  img {
    grid-column: 2;
    grid-row: 1;
    max-width: 100%;
    max-height: 100%;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6.6vh;
    
    .text {
      font-family: 'Chapaza';
      font-size: 60px;
      color: #212028;

      @media (max-width: 1360px) {
        font-size: 48px;
      }
    }

    .line {
      width: 5.5vw;
      height: 2px;
      background-color: #C84836;
      margin-top: 6.3vh;
    }
  }

  .description {
    font-family: 'Lato';
    font-size: 22px;
    font-color: #212028;
    width: 26.6vw;
    margin-bottom: 7vh;
    margin-top: 218px;

    @media (max-width: 1360px) {
      font-size: 20px;
    }
  }
`;

const ChristianActivist = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer />
    ) : (
        <Container>
          <img src={ChristianActivistImg} />
          <div className="text-container">
            <div className="title">
              <div className="text">Christian</div>
              <div className="text">Activist</div>
              <div className="line" />
            </div>
            <div className="description">
              <div>
                Bill Armstrong was a vital leader in the national evangelical movement, and one of the most highly sought speakers at Christian events across the nation. With good humor and charming wit, he lived the life of a Christian statesman, involved in the most important issues of the day, with an approach centered on Christian principles. Billy Graham called him “the most inspirational and enlightening speaker” the National Prayer Breakfast ever had.
          </div>
              <ReadMoreButton route="/bio/christian" color="#C84836" styles={{ margin: 'unset', marginTop: '4.2vh' }} />
            </div>
          </div>
        </Container >
      )
  )
}

export default ChristianActivist;