import React from 'react';
import styled from 'styled-components';

import ChristianActivistImg from '../../../images/president-armstrong-ccu-inauguration.png';


const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

   img {
    max-width: 60%;
    max-height: 60%;
    margin: 10%;
  }
  
  .text {
    background-color: white;
    font-family: 'Lato';
    font-size: 2.3vw;
    line-height: 30px;
    width: 80%;
    height: 80%;
    box-shadow: 0px -4px 50px rgba(50, 50, 50, 0.3);
    padding: 20px;

      h1 {
        font-family: 'Chapaza';
        color: #212028;
        font-size: 10.0vw;
        line-height: 70px;
        font-weight: normal;
        margin: auto;
        text-align: center;
      }
      
      .line {
        width: 94px;
        height: 2px;
        background-color: #C84836;
        margin: 25px auto;
      }
  }
`;

const Christian = () => (
  <MobileContainer>
    <div className="text">
      <h1>Christian Activist</h1>
      <div className="line" />
      <div>
        Bill Armstrong was a vital leader in the national evangelical movement, and one of the most highly sought speakers at Christian events across the nation. With good humor and charming wit, he lived the life of a Christian statesman, involved in the most important issues of the day, with an approach centered on Christian principles. Billy Graham called him “the most inspirational and enlightening speaker” the National Prayer Breakfast ever had.
      </div>
      <img src={ChristianActivistImg} />
    </div>
  </MobileContainer>
)

export default Christian;