import React from 'react';
import styled from 'styled-components';
import businessman from '../../images/Group 9.png';
import businessmanCopy from '../../images/Businessman Copy.png';
import ReadMoreButton from '../../components/common/ReadMoreButton';
import MobileContainer from './mobile/businessman';
import { useViewport } from '../../hooks/useViewport';

const Container = styled.div`
  height: 110vh;
  width: 100%;
  display: grid;
  grid-template-columns: 31.6vw 30.6vw 26.8vw;
  grid-template-rows: 100%;
  column-gap: 5.5vw;

  .left-image {
    max-width: 45vw;
    max-height: 83vh;
    margin-top: 7vh;
    grid-column: 1;
    grid-row: 1;
    margin-left: 72px;

    @media (max-width: 1360px) {
      max-width: 35vw;
      max-height: 73vh;
    
    }
  }

  .right-image {
    grid-column: 3;
    grid-row: 1;
    max-width: 21.9vw;
    max-height: 52.5vh;
    justify-self: end;
    margin-top: 18.7vh;
    margin-left: 72px;
  }
`;

const MiddleContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  margin-left: 87px;
`;

const TextContainer = styled.div`
  font-family: 'Lato';
  font-size: 22px;
  line-height: 30px;
  margin-top: 23.7vh;

  @media (max-width: 1360px) {
    font-size: 18px;
    margin-top: 10vh;
  }
`;

const BusinessmanText = styled.div`
  font: 'Chapaza';
  font-size: 60px;
  color: #212028;
  grid-column: 2;
  grid-row: 1;
  margin-left: 27.5vw;
  margin-bottom: 20vh;
  align-self: end;

  @media (max-width: 1360px) {
    font-size: 48px;
    margin-left: 32.5vw;
  }
`;

const Businessman = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer />
    ) : (
        <Container>
          <img className="left-image" src={businessman} />
          <MiddleContainer>
            <TextContainer>
              Bill Armstrong was highly successful as an entrepreneur and small businessman. Starting in radio, he broadened his family's portfolio into newspaper publishing, television broadcasting, and eventually the financial services and real estate businesses. Many of these ventures were in partnership with his son Wil, including his last private business, a software development firm. He also served on the boards of numerous public companies.
        </TextContainer>
            <ReadMoreButton route="/bio/businessman" color="#C84836" styles={{ gridColumn: 2, gridRow: 1, justifySelf: 'center', margin: 'unset', marginTop: '11vh' }} />
          </MiddleContainer>
          <BusinessmanText>Businessman</BusinessmanText>
          <img className="right-image" src={businessmanCopy} />
        </Container >
      )
  )
}

export default Businessman