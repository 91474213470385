import React from 'react';
import styled from 'styled-components';

import AboutBillImg from '../../../images/Group 11.png';

const MobileContainer = styled.div`
  background-color: #f6f4ef;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
      img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const TextContainer = styled.div`
  font-family: 'Lato';
  font-size: 3.3vw;
  margin: 10%;
`;

const About = () => (
  <MobileContainer>
    <img src={AboutBillImg} />
    <TextContainer>
      William L. Armstrong was born in Fremont, Nebraska March 16, 1937, and died July 15, 2016 in Denver. He was a highly successful businessman, starting in radio, then newspapers and television, and eventually the financial services and real estate businesses. At the same time, he was a pivotal figure in Colorado politics for 50 years. He became an important national leader in the U.S. Senate, an influential figure in the Christian evangelical movement, and led one of the nation’s top private Christian universities.
    </TextContainer>
  </MobileContainer>
)

export default About;