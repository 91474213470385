import React from "react"
import Layout from '../components/layout';
import TitleCard from "../components/home/title";
import Broadcaster from "../components/home/broadcaster";
import Businessman from '../components/home/businessman';
import Statesman from '../components/home/statesman';
import ChristianActivist from '../components/home/christianActivist';
import Educator from '../components/home/educator';
import AboutBillArmstrong from '../components/home/AboutBillArmstrong';
import { Helmet } from 'react-helmet';

const IndexPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>The Armstrong Project preserves and promotes the legacy of Senator William Armstrong</title>
      <script>
        {`
         
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        
        `}
      </script>
    </Helmet>
    <Layout stacked={false}>
      <TitleCard></TitleCard>
      <Broadcaster />
      <Businessman />
      <Statesman />
      <ChristianActivist />
      <Educator />
      <AboutBillArmstrong />
    </Layout>
  </>
)

export default IndexPage
