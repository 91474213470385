import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../hooks/useViewport';
import background from '../../images/Bitmap.png';
import MobileContainer from './mobile/broadcaster';

import ReadMoreButton from '../../components/common/ReadMoreButton';

const Container = styled.div`
  height: 100vh;
  margin-top: -10vh;
  width: 100%;
  position: relative;
  z-index: 200;
  background-image: url(${background});

  .layer {
    background-color: #b94f3d;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.85;
  }

  .grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 41.4vw 59.1vw;
    grid-template-rows: 100%;
    column-gap: 5.5vw;
    position: absolute;
  }
`;

const LeftContainer = styled.div`
  grid-column 1;
  grid-row: 1;
  z-index: 99;
`;

const TextContainer = styled.div`

  width: 31vw;
  height: 65.7vh;
  background: white;
  z-index: 99;
  margin: -7.3vh auto 0;
  border-top: 0px solid #202020;
  box-shadow: 0px -4px 50px rgba(50, 50, 50, 0.3);
  padding: 18px 40px 33px;
  box-sizing: border-box;

  h1 {
    @font-face {
      font-family: 'Chapanza';
      src: url('../fonts/Chapanza.ttf');
    }
    color: #212028;
    font-size: 60px;
    line-height: 70px;
    font-weight: normal;
    margin: auto;
    text-align: center;
  }

  .line {
    width: 94px;
    height: 2px;
    background-color: #C84836;
    margin: 25px auto;
  }

  .text {
    font-family: 'Lato';
    font-size: 22px;
    line-height: 30px;
  }

  @media (max-width: 1360px) {
    h1 {
      font-size: 48px;
    }

    .text {
      font-size: 14px;
    }
  }
`;

const ReadMore = styled.div`
  width: 28vw;
  height: 9.2vh;
  background-color: #ba503e;
  margin: auto;
  border-bottom: 0px solid #202020;
  box-shadow: 0px 40px 50px rgba(50, 50, 50, 0.4);
  position: relative;
`;

const ShadowBox = styled.div`
  width: 24.8vw;
  height: 4.6vh;
  background-color: #ba503e;
  margin: auto;
`;

const Broadcaster = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  return (
    width <= breakpoint ? (
      <MobileContainer />
    ) : (
        <Container>
          <div className="grid">
            <LeftContainer>
              <TextContainer>
                <h1>Broadcaster</h1 >
                <div className="line" />
                <div className="text">
                  At 19, Bill Armstrong was America’s first teenage Top 40 Disc Jockey, beginning his lifetime in the broadcasting business. In 1959 he bought KOSI radio and became a well-known personality in Denver. He was president of Armstrong Broadcasting Company and Ambassador Media Corporation, owned a major daily newspaper and three TV stations, and served on the board of the AP Broadcasters Association.
          </div>
              </TextContainer >
              <ReadMore>
                <ReadMoreButton route="/bio/broadcaster" color={'white'} />
              </ReadMore>
              <ShadowBox />
            </LeftContainer >
            <img
              src={background}
              style={{
                maxWidth: '52.6vw',
                maxHeight: '52.6vh',
                gridColumn: 2,
                gridRow: 1,
                width: 'max-content',
                height: 'max-content',
                objectFit: 'cover',
                objectPosition: 'top',
                zIndex: 99,
                marginTop: '22vh',
              }}
            />
          </div >
          <div className="layer" />
        </Container >
      )

  )
}

export default Broadcaster;