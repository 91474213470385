import React from 'react';
import styled from 'styled-components';

import EducatorImg from '../../../images/Group 6.png'

const MobileContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 12px;

  img {
    max-width: 60%;
    max-height: 60%;
  }
`;

const TextContainer = styled.div`
  font-family: 'Lato';
  font-size: 3.3vw;
  margin: 10%;
`;

const EducatorText = styled.div`
  margin: auto;
  font-family: 'Chapaza';
  font-size: 10.5vw;
  color: #212028;
`;

const Educator = () => (
  <MobileContainer>
    <EducatorText>Educator</EducatorText>
    <img src={EducatorImg} />
    <TextContainer>
      Bill Armstrong was highly successful as an entrepreneur and small businessman. Starting in radio, he broadened his family's portfolio into newspaper publishing, television broadcasting, and eventually the financial services and real estate businesses. Many of these ventures were in partnership with his son Wil, including his last private business, a software development firm. He also served on the boards of numerous public companies.
    </TextContainer>
  </MobileContainer>
);

export default Educator;